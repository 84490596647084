<template>
  <v-navigation-drawer class="navigation-drawer"
    bottom
    color="transparent"
    fixed
    min-height= "100%"
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="#1c7ed5"
      shaped
      class=""
    >
      <v-list-item
        v-for="name in items"
        :key="name"
        :to="{ name }"
        :exact="name === 'Home'"
        active-class="list"
        dark
        @click="handleClick(name)"
      >
        <v-list-item-content>
          <v-list-item-title  v-text="name"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>


</template>

<script>
  export default {
    name: 'Drawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },

    methods: {
      handleClick(name) {
        const itemsOpenNewTab = [{
          name: 'Carteira Convênio',
          url: 'http://carteira.bancariosuberlandia.org.br'
        }]

        const findItem = itemsOpenNewTab
          .find(item => item.name === name)

        if (findItem) {
          this.openInNewTab(findItem);
        } else {
          this.$router.push({ name });
        }
      },

      openInNewTab(item) {
        const url = item.url

        if (url) {
          window.location.href = url
          // window.open(url, '_blank')
        }
      },
    },
  }
</script>

<style lang="sass">
// .v-navigation-drawer--bottom.v-navigation-drawer--is-mobile
//   min-height: calc(100% - 80px)

.navigation-drawer
  z-index: 4  !important

.list
  color: white !important
  font-weight: 600

</style>
